import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { removeToken } from '../../utils/handleToken';
// import { PostPasswordRes } from 'services/pds/types';
// import { removeToken } from 'utills/handleToken';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache, no-store, must-revalidate',
		Pragma: 'no-cache',
		Expires: 0,
	},
});

let cancelTokenSource = axios.CancelToken.source();

// request interceptors
instance.interceptors.request.use(
	(config) => {
		const newConfig = { ...config, cancelToken: cancelTokenSource.token };
		// token auto push
		const token = window.sessionStorage.getItem('accessToken');

		const url = config.url?.replace(/\?.*$/, '');

		if (token && url === '/pds') {
			newConfig.headers['Api-Token'] = token;
		}

		return newConfig;
	},
	async (err) => {
		return await Promise.reject(err);
	},
);

// response interceptor
instance.interceptors.response.use(
	(res: AxiosResponse<unknown>) => {
		return res;
	},
	async (err: AxiosError) => {
		const { response } = err;
		switch (response?.status) {
			case 401:
				cancelTokenSource.cancel('Session refresh failed, cancelling all requests.');

				cancelTokenSource = axios.CancelToken.source();
				removeToken();
				break;
			case 409:
				toast.error('현재 너무 많은 요청이 발생하고 있습니다.\n잠시 후에 다시 시도해 주십시오.');
				break;
			default:
				break;
		}
		return await Promise.reject(err);
	},
);

export default instance;
